import { authApi } from "@/api";
import {
  EUserRole,
  ITokenPair,
  StorageKey,
  TranspositionCipher,
  UserFingerprintGenerator,
} from "@/shared";
import { isLoading, ResetAccount } from "@/store/account";
import { ResetTokens, SaveTokens } from "@/store/auth";
import { UnselectChat } from "@/store/chats";
import { Reset } from "@/store/shared";
import { simpleDispatch } from "@/store/store-helpers";
import { ClearFilter } from "@/store/task";
import { ClearContactsFilter, ClearUsersFilter } from "@/store/users";
import _ from "lodash";
import moment from "moment";
import {
  GlobalContainerService,
  notificationsService,
  SocketIo,
} from "../system";
import { accountService } from "./account.service";
import { configsService } from "./configs.service";
import { permissionsService } from "./permissions.service";

const store = () => GlobalContainerService.get("store");
interface ISignIn {
  login: string;
  password: string;
  rememberMe: boolean;
}

interface ISendCode {
  phoneNumber: string;
}

interface IRecoverPassword {
  phoneNumber: string;
  newPassword: string;
  code: string;
}

const afterAuth = () => notificationsService.runOneSignal();

const signIn = async (payload: ISignIn) => {
  simpleDispatch(new isLoading({ isLoading: true }));
  try {
    const { data } = await authApi.signIn({
      ...payload,
      deviceName: "web",
      deviceUuid: UserFingerprintGenerator.generateUserFingerprint(),
    });

    if (data) await _saveTokens(data);
    localStorage.setItem(StorageKey.Login, payload.login);
    await accountService.getAccount();
    await permissionsService.loadAvailablePermissions();

    if (store().getState()?.account?.account?.role !== EUserRole.Admin)
      await permissionsService.loadMyPermissions();

    if (payload.rememberMe) {
      localStorage.setItem(StorageKey.RememberMe, "true");
    } else {
      localStorage.setItem(
        StorageKey.ExpiryDate,
        moment()
          .add(1, "day")
          .toString()
      );
    }

    afterAuth().catch((e) => console.log("error on after auth", e));
    await configsService.loadFilesLimitsConfig();
  } catch (e) {
    console.log("AUTH ERROR", e);
  } finally {
    simpleDispatch(new isLoading({ isLoading: false }));
  }
};

const autoAuth = async () => {
  try {
    simpleDispatch(new isLoading({ isLoading: true }));
    const existTokens = await _getTokensFromStorage();
    if (!existTokens.refreshToken) throw new Error();
    if (localStorage.getItem(StorageKey.RememberMe) === "true") {
      await refreshSession(existTokens.refreshToken);
      await loadDataAfterAuth();
    } else if (moment(localStorage.getItem(StorageKey.ExpiryDate)) > moment()) {
      await refreshSession(existTokens.refreshToken);
      await loadDataAfterAuth();
    }

    afterAuth().catch((e) => console.log("error on after auth", e));

    await configsService.loadFilesLimitsConfig();
  } catch (e) {
    console.log("AUTO AUTH ERROR", e);
  } finally {
    simpleDispatch(new isLoading({ isLoading: false }));
  }
};

const onLogout = async () => {
  const socketIo = SocketIo.get();
  socketIo._disconnect();
  await _resetTokens();
  localStorage.removeItem(StorageKey.Login);
  localStorage.removeItem(StorageKey.Path);
  simpleDispatch(new ResetAccount());
  simpleDispatch(new Reset());
  simpleDispatch(new ClearUsersFilter());
  simpleDispatch(new ClearFilter());
  simpleDispatch(new ClearContactsFilter());
  simpleDispatch(new UnselectChat());
  simpleDispatch(new isLoading({ isLoading: false }));
};

const logout = async (refreshToken: string) => {
  try {
    simpleDispatch(new isLoading({ isLoading: true }));
    await authApi.logout({ refreshToken });
    await onLogout();
  } catch (e) {
    console.log("LOGOUT ERROR", e);
    if (e.response.status === 401) {
      await refreshSession(refreshToken);
      const { refreshToken: refresh } = await _getTokensFromStorage();
      await logout(refresh);
    } else await _resetTokens();
  } finally {
    simpleDispatch(new isLoading({ isLoading: false }));
  }
};

const stopSession = async () => {
  try {
    await onLogout();
  } catch (e) {
    console.log("LOGOUT ERROR", e);
    await _resetTokens();
  }
};

const refreshSession = async (refreshToken?: string, attempt = 1) => {
  let token = refreshToken;
  if (!token) {
    const existTokens = await _getTokensFromStorage();
    token = existTokens?.refreshToken;
  }

  if (!token) return;

  try {
    const { data } = await authApi.sendRefreshToken({
      refreshToken: token,
    });
    await _saveTokens(data);
  } catch (e) {
    if (attempt > 2) throw e;
    await refreshSession(refreshToken, attempt + 1);
  }
};

const loadDataAfterAuth = async () => {
  await accountService.getAccount();
  if (_.isEmpty(store().getState()?.permissions?.availablePermissions))
    await permissionsService.loadAvailablePermissions();

  if (store().getState()?.account?.account?.role !== EUserRole.Admin)
    await permissionsService.loadMyPermissions();
};

const _resetTokens = async () => {
  await localStorage.removeItem(StorageKey.AccessToken);
  await localStorage.removeItem(StorageKey.RefreshToken);
  simpleDispatch(new ResetTokens());
};

const _getTokensFromStorage = async () => {
  const accessToken: string = await GlobalContainerService.get(
    "store"
  ).getState().auth.accessToken;
  const refreshToken: string = await localStorage.getItem(
    StorageKey.RefreshToken
  );

  return { accessToken, refreshToken };
};

const _saveTokens = async (tokens: ITokenPair) => {
  await localStorage.setItem(StorageKey.RefreshToken, tokens.refreshToken);
  await localStorage.setItem(StorageKey.AccessToken, tokens.accessToken);

  simpleDispatch(new SaveTokens(tokens));
};

const sendConfirmationCode = async (payload: ISendCode) => {
  await authApi.recoveryCodeReq(payload);
};

const recoverAccountPassword = async (payload: IRecoverPassword) => {
  simpleDispatch(new isLoading({ isLoading: true }));

  try {
    const { data } = await authApi.passwordRecoveryReq({
      ...payload,
      deviceName: "web",
      deviceUuid: UserFingerprintGenerator.generateUserFingerprint(),
    });

    if (data) await _saveTokens(data);

    await accountService.getAccount();
    await permissionsService.loadAvailablePermissions();

    if (store().getState()?.account?.account?.role !== EUserRole.Admin)
      await permissionsService.loadMyPermissions();

    localStorage.setItem(
      StorageKey.ExpiryDate,
      moment()
        .add(1, "day")
        .toString()
    );
  } catch (e) {
    console.log("AUTH ERROR", e.message);
  } finally {
    simpleDispatch(new isLoading({ isLoading: false }));
  }
};

export const authService = {
  signIn,
  autoAuth,
  sendConfirmationCode,
  recoverAccountPassword,
  refreshSession,
  logout,
  stopSession,
  _getTokensFromStorage,
  _saveTokens,
};
